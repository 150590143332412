import React from "react";
import GooglePlay from "../assets/images/google_play.svg";
import ApplePlay from "../assets/images/apple_play.svg";
import QRcode from "../assets/images/qr_code.svg";
import SnapShop from '../assets/images/snap_a_photo.png';
import AIAnalysis from '../assets/images/ai_analysis.png';
import Feedback from '../assets/images/feedback.png';
import '../stylesheet/card.css'

const GetStarted: React.FC = () => {
    return (
        <section className="get-started bg-white pt-3">
            <div>
                <div className="d-flex align-items-center justify-content-center text-center flex-column">
                    <h1 style={{ color: "#535CE8", fontWeight: 700 }}>
                        Ready to get started?
                    </h1>
                    <p style={{ margin: "0px", fontWeight: 400, fontSize: "16px" }}>
                        Join us today and transform your style with AI-powerd
                        recommandations.
                    </p>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column flex-md-row text-center pt-4 gap-5">
                    <div className="">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ai.fitcheckai">
                            <img
                                src={GooglePlay}
                                className="img-fluid scale-img"
                                style={{}}
                                alt="googleplay"
                            />
                        </a>
                    </div>
                    <div className="">
                        <a target="_blank" href="https://play.google.com/store/games?device=windows&pli=1">
                            <img
                                src={QRcode}
                                className="img-fluid scale-img"
                                style={{}}
                                alt="QRcode"
                            />
                        </a>
                    </div>
                    <div className="">
                        <a target="_blank" href="https://apps.apple.com/us/app/fitcheck-ai/id6683301469">
                            <img
                                src={ApplePlay}
                                className="img-fluid scale-img"
                                style={{}}
                                alt="ApplePlay"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container ps-4 pe-4 mt-5">
                <div className="row g-4 d-flex align-items-center justify-content-between">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="card custom-card" style={{ cursor: 'pointer' }}>
                            <img src={SnapShop} className="card-img-top card-img-custom" alt="Card-image" />
                            <div className="card-body">
                                <h5 className="card-title">Snap a Photo</h5>
                                <p className="card-text" style={{margin:'0px'}}>
                                    Take a photo of your outfit using the FitCheckAI app.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="card custom-card" style={{ cursor: 'pointer' }}>
                            <img src={AIAnalysis} className="card-img-top card-img-custom" alt="Card-image" />
                            <div className="card-body">
                                <h5 className="card-title">AI Analysis</h5>
                                <p className="card-text" style={{margin:'0px'}}>
                                Our advanced AI analyzes your outfit, offering personalized style.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4" >
                        <div className="card custom-card" style={{ cursor: 'pointer' }}>
                            <img src={Feedback} className="card-img-top card-img-custom" alt="Card-image" />
                            <div className="card-body">
                                <h5 className="card-title">Get Feedback</h5>
                                <p className="card-text" style={{margin:'0px'}}>
                                    Receive personalized style recommendation for you.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GetStarted;
